const Arrays = require('./array')
const DEFAULT_BATCH_LIMIT = 100

async function executeFunctions(fns, {
  parallel = 1,
} = {}) {
  if (
    parallel < 1 || parallel % 1 !== 0
  ) throw new Error(`Incorrect parallelization argument! [${parallel}]`)

  const toExecute = [...fns]
  const results = []
  const lineCount = Math.min(toExecute.length, parallel)
  const getFn = () => toExecute.shift()
  const lines = Array(lineCount).fill(0).map(async () => {
    while (toExecute.length) {
      const result = await getFn()()
      results.push(result)
    }
  })
  await Promise.all(lines)

  return results
}

async function processExecutions({
  batchLimit = DEFAULT_BATCH_LIMIT,
  fn,
  mergeInto,
  parallel = 1,
  splitOn,
  [splitOn]: array,
  ...options
}) {
  const batches = Arrays.splitToBatches(array, batchLimit)
  const resultsArrayed = await executeFunctions(
    batches.map(
      batch => () => fn({
        [splitOn]: batch,
        ...options,
      })
    ), { parallel }
  )

  if (mergeInto instanceof Array) {
    return resultsArrayed.reduce(
      (total, partial) => {
        total.push(...partial)

        return total
      }, mergeInto
    )
  } else if (
    mergeInto !== null &&
    typeof mergeInto === 'object'
  ) {
    return resultsArrayed.reduce(
      (total, partial) => Object.assign(total, partial), mergeInto
    )
  } else {
    return resultsArrayed.reduce(
      (total, partial) => [
        ...total,
        ...(partial instanceof Array ? partial : [partial])
      ], []
    )
  }
}

const wait = delay => new Promise((resolve, reject) => {
  if (typeof delay !== 'number' || delay < 0) {
    return reject(
      new Error('Incorrect delay provided to wait function!')
    )
  }
  setTimeout(() => resolve(), (delay + 1))
})

module.exports = {
  DEFAULT_BATCH_LIMIT,
  execute: executeFunctions,
  process: processExecutions,
  wait,
}
