import { getCSVContent } from '../getCSVContent'

export const downloadCSVContent = ({ fields, items }) => {
  const csvString = getCSVContent({
    fields,
    rows: items
  })
  const content = encodeURI(`data:text/csv;charset=utf-8,${csvString}`)
  const link = document.createElement('a')

  link.setAttribute('href', content)
  link.setAttribute('download', `client-download_${Date.now()}.csv`)
  document.body.appendChild(link)
  link.onclick = () => {
    document.body.removeChild(link)
  }
  link.click()
}
