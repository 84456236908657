const { types: clientTypes } = require('../clients/constants')
const modelDecileBiddingTypeId = 1
const modelFactorMultiplierTypeId = 2
const campaignConstants = {
  batchUpdateChannel: 'campaignUpdateBatch',
  // Calculate goal completion
  clientTypes,
  defaultBidValues: ['Default', 'default', null, 0, '0'],
  editCategoryGroupsAvailable: [
    'administrative',
    'communication',
    'operative',
    'unknown',
  ],
  editCategoryGroups: {
    ad_placement: 'operative',
    ad_position_update: 'operative',
    add_campaign_tags: 'administrative',
    adops: 'administrative',
    adops_assignment: 'administrative',
    advanced_adops: 'operative',
    advanced_exchanges: 'operative',
    advanced_margin_overrides: 'operative',
    advanced_rebate: 'administrative',
    advanced_product_categories: 'administrative',
    advanced_sensitive_categories: 'administrative',
    advanced_search_targeting: 'operative',
    advertiser: 'operative',
    attribution_windows: 'administrative',
    auto_optimization: 'operative',
    bid_multiplier: 'operative',
    broad_match_add_anchor: 'operative',
    broad_match_remove_anchor: 'operative',
    broad_match_remove: 'operative',
    browsers: 'operative',
    budget: 'operative',
    budget_bidding: 'operative',
    budget_goals: 'operative',
    budget_impressions: 'operative',
    budget_plans: 'operative',
    budget_spend: 'operative',
    campaign_custom_id: 'administrative',
    campaign_goal: 'operative',
    campaign_group: 'operative',
    campaign_name: 'administrative',
    campaign_status: 'operative',
    campaign_type: 'operative',
    categories: 'administrative',
    comments: 'communication',
    conversions: 'administrative',
    conversions_geo: 'administrative',
    context_remove: 'operative',
    creative_flights: 'operative',
    creative_pacing: 'operative',
    creatives: 'operative',
    creatives_media_type: 'operative',
    cs_assignment: 'administrative',
    custom_values: 'administrative',
    dayparting: 'operative',
    deals: 'operative',
    devices: 'operative',
    exchanges: 'operative',
    factor_attribute: 'operative',
    factor_model_attribute: 'operative',
    factor_multiplier: 'operative',
    factors_enabled: 'operative',
    fcap_control_enabled: 'administrative',
    first_party_segments: 'operative',
    flag_add: 'communication',
    frequency_capping: 'operative',
    geo_fencing_recency: 'operative',
    impression_capping: 'operative',
    inventory_ad_placement: 'operative',
    inventory_browsers: 'operative',
    inventory_dayparting: 'operative',
    inventory_deals: 'operative',
    inventory_devices: 'operative',
    inventory_domains: 'operative',
    inventory_operating_systems: 'operative',
    inventory_viewability: 'operative',
    ip_ranges: 'operative',
    keywords: 'operative',
    keyword_add: 'operative',
    keyword_bid: 'operative',
    keyword_categories: 'operative',
    keyword_remove: 'operative',
    keyword_removal: 'operative',
    listed_domains: 'operative',
    listed_domains_remove: 'operative',
    locations: 'operative',
    margin_overrides: 'operative',
    mass_changes: 'administrative',
    mass_changes_enabled: 'administrative',
    media_type: 'operative',
    model_enabled: 'operative',
    model_factors_enabled: 'operative',
    model_switch_disabled: 'operative',
    model_type_id: 'operative',
    monthly_limit: 'operative',
    mock: 'operative',
    multiplier_enabled: 'operative',
    multiplier_update: 'operative',
    notes: 'communication',
    oba_compliance: 'administrative',
    operating_systems: 'operative',
    organization_blocklist_enabled: 'operative',
    pacing: 'operative',
    page_contexts: 'operative',
    pg_inventory_deal: 'administrative',
    product_category: 'administrative',
    rebate: 'administrative',
    recency: 'operative',
    remove_campaign_tags: 'administrative',
    retail_markup: 'operative',
    retargeting: 'operative',
    schedule: 'operative',
    search_targeting: 'operative',
    segment_remove: 'operative',
    sensitive_category: 'administrative',
    settings_advertiser: 'administrative',
    settings_campaign_group: 'administrative',
    settings_campaign_type: 'operative',
    settings_comments: 'communication',
    settings_custom_id: 'communication',
    settings_custom_values: 'administrative',
    settings_flight: 'operative',
    settings_frequency_capping: 'operative',
    settings_oba_compliance: 'operative',
    settings_retail_markup: 'operative',
    spend_type: 'administrative',
    status: 'operative',
    sub_type: 'administrative',
    targeting_geo: 'operative',
    targeting_ip_ranges: 'operative',
    targeting_keywords: 'operative',
    targeting_keyword_categories: 'administrative',
    targeting_locations: 'operative',
    targeting_page_contexts: 'operative',
    targeting_segments: 'operative',
    targeting_segments_labels: 'operative',
    targeting_segments_third_party: 'operative',
    third_party_segments: 'operative',
    third_party_tags: 'operative',
    viewability: 'operative',
  },
  statusIndicators: [
    {
      className: 'text-success',
      icon: 'check-circle',
      text: 'Live',
      title: 'Campaign is live',
      type: 'live',
    },
    {
      className: 'text-warning',
      icon: 'times-circle',
      text: 'Activated, not started',
      title: 'Campaign is activated but has NOT started yet',
      type: 'activated',
    },
    {
      className: 'text-warning',
      icon: 'exclamation-circle',
      text: 'Pending',
      title: 'Campaign is pending',
      type: 'pending',
    },
    {
      className: 'text-danger',
      icon: 'exclamation-circle',
      text: 'Suspended',
      title: 'Campaign is suspended',
      type: 'suspended',
    },
    {
      className: 'text-warning',
      icon: 'pause-circle',
      text: 'Paused',
      title: 'Campaign is paused',
      type: 'paused',
    },
    {
      className: 'text-default',
      icon: 'exclamation-circle',
      text: 'Draft',
      title: 'Campaign is in draft mode',
      type: 'draft',
    },
    {
      className: 'text-default',
      icon: 'stop-circle',
      text: 'Ended',
      title: 'Campaign has ended',
      type: 'ended',
    },
    {
      className: 'text-danger',
      icon: 'times-circle',
      text: 'Inactive',
      title: 'Campaign is inactive',
      type: 'inactive',
    },
    {
      className: 'text-danger',
      icon: 'exclamation-circle',
      text: 'Unknown',
      title: 'Campaign status is unknown',
      type: 'unknown',
    },
  ],
  statuses: {
    1: 'Active',
    2: 'Paused',
    3: 'Suspended',
    4: 'Inactive',
    5: 'Pending',
  },
  types: {
    0: 'All',
    1: 'Search',
    2: 'Contextual',
    3: 'Site retargeting',
    4: 'IP targeting',
    5: 'Geo optimized',
    6: 'Programmatic gtd',
  },
  adTypes: {
    1: 'Image',
    3: 'Flash',
    4: 'HTML',
    5: 'Facebook',
    6: 'Video',
    8: 'Click-To-Call',
    7: 'Third Party Video',
  },
  geoFenceTargetTypeId: 1,
  goalRanges: {
    ctr: {
      defaultValue: 0.1,
      min: 0.01,
      max: 0.5,
      step: 0.01,
      vector: 1,
    },
    cpm: {
      defaultValue: 2,
      min: 0.1,
      max: 50,
      step: 0.1,
      vector: -1,
    },
    cpc: {
      defaultValue: 3,
      min: 0.1,
      max: 50,
      step: 0.1,
      vector: -1,
    },
    ecpa: {
      defaultValue: 10,
      min: 1,
      max: 100,
      step: 1,
      vector: -1,
    },
    vcr: {
      defaultValue: 95,
      min: 1,
      max: 120,
      step: 1,
      vector: 1,
    },
    none: {
      defaultValue: 0,
      min: 0,
      max: 100,
      step: 1,
      vector: 1,
    },
  },
  goalTypes: ['none', 'ctr', 'cpa', 'cpc', 'vcr'],
  mediaTypes: [
    { id: 1, name: 'Display' },
    { id: 2, name: 'Video' },
    { id: 3, name: 'Facebook' },
    { id: 4, name: 'Billboard' },
    { id: 5, name: 'Audio' },
  ],
  recencies: [
    {
      id: 4,
      isDefault: true,
      name: '1 month',
      position: 1,
    },
    {
      id: 1,
      name: '2 weeks',
      position: 2,
    },
    {
      id: 3,
      name: '1 week',
      position: 3,
    },
    {
      id: 2,
      name: '1 day',
      position: 4,
    },
    {
      id: 10,
      name: '1 hour',
      position: 5,
    },
    {
      id: 9,
      name: '30 minutes',
      position: 6,
    },
    {
      id: 8,
      name: '15 minutes',
      position: 7,
    },
    {
      id: 7,
      name: '10 minutes',
      position: 8,
    },
    {
      id: 6,
      name: '5 minutes',
      position: 9,
    },
    {
      id: 5,
      name: 'Instant',
      position: 10,
    },
  ],
  modelDecileBiddingTypeId,
  modelFactorMultiplierTypeId,
  modelTypes: [
    {
      id: modelDecileBiddingTypeId,
      name: 'Decile bidding',
    },
    {
      id: modelFactorMultiplierTypeId,
      name: 'Factor multipliers',
    },
  ],
  modelPoolTypes: [
    { id: 0, name: 'None' },
    { id: 1, name: 'Campaign' },
    { id: 2, name: 'Campaign Pool' },
    { id: 3, name: 'Campaign Type' },
    { id: 4, name: 'Product Category' },
    { id: 5, name: 'Company' },
    { id: 6, name: 'Platform' },
    { id: 8, name: 'Tag' },
    { id: 100, name: 'Campaign Type by Product Category' },
    { id: 101, name: 'Campaign Type by Company' },
    { id: 102, name: 'Campaign Type by Platform' },
    { id: 103, name: 'Campaign Type by Product Category by Company' },
    {
      id: 104,
      name: 'Campaign Type by Product Category by Platform',
    },
    { id: 105, name: 'Product Category by Company' },
    { id: 106, name: 'Product Category by Platform' },
    { id: 1000, name: 'Proposed Campaign Pool' },
    { id: 9999, name: 'System Default' },
  ],
  keywordLowBid: 0.001,
  keywordMaxBid: 50,
  keywordMaxBidUpdateMultiplier: 1.5,
  refreshTimestamp: 'refreshedAt',
  segmentTypes: ['any', 'all'],
  segmentTargetTypes: { 1: 'Exclude', 2: 'Include' },
  subTypeValues: ['42L', '21L', 'None'],
  trackingMaxQueueLength: 7,
  budgetLimitTypes: ['below', 'above'],
  domainListTypes: {
    0: 'allowlist',
    1: 'blocklist',
  },
  updateDataChannel: 'campaign_update_data',
  updateEvent: 'campaignUpdated',
  updateRequestChannel: 'campaignUpdateRequest',
}

module.exports = {
  ...campaignConstants,
  editCategories: Object.keys(campaignConstants.editCategoryGroups),
  defaultRecency: campaignConstants.recencies.find(
    ({ isDefault }) => isDefault,
  ),
}
