const ACRE = 4046.86

// @param {String} str - eg:
// '((-82.920514,40.049254),(-82.920514,40.050642),(-82.918691,40.050642),(-82.918691,40.049254))'
function coordsFromString(str) {
  // 1. Cut double round brackets
  // 2. Split on the inter round brackets and the comma
  // 3. To parse out the coords for points, split by comma and convert to float,
  // only keep 6 decimal places
  return (
    str
      .replace(/(\(\(|\)\))/gi, '')
      .split('),(')
      .map(
        pointCoordsStr => pointCoordsStr.split(',').map(
          coord => parseFloat(parseFloat(coord).toFixed(6))
        )
      )
  )
}

// @param {Array: [ Array, ... ]} str - eg:
// [ [ -82.64679, 40.007018 ],
//   [ -82.64679, 40.009771 ],
//   [ -82.642048, 40.009771 ],
//   [ -82.642048, 40.007018 ] ]
// Only keep 6 digits
function coordsToString(shapeCoords) {
  return `(${shapeCoords.map(pointCoords => `(${pointCoords.map(float => float.toFixed(6)).join(',')})`).join(',')})`
}

// Little bit of Pytagoras for distance calculation
// dist = sqrt((x2-x1)^2 + (y2-y1)^2)
function distanceBetween([x1, y1], [x2, y2]) {
  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))
}

// Geo distance in metres
function distanceInMetres([lon1, lat1], [lon2, lat2]) {
  const R = 6378.137 // Radius of earth in KM
  const dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180
  const dLon = lon2 * Math.PI / 180 - lon1 * Math.PI / 180
  const a = (
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
  )
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = (R * c)
  return (d * 1000) // meters
}

class Shape {
  constructor(coords) {
    this.coords = coords
    this.lengths = {}
    this.diagonals = { x: 0, y: 0 }
    this.area = 0
    this.areaAcres = 0
  }

  /**
   * This will check the distances between vertex coordinates and will return if the
   * edges btween them form a rectangle
   *
   * @param { Array } - Array containing two member arrays for point coordinates
   * @returns { Boolean } - True if supplied coordinates are not those of a rectangle
   * Coordinate indexes, how they are supplied
   *
   *      ______b_____
   *      1           2
   *      |           |
   *      a           c
   *      |           |
   *      0_____d_____3
   *
   *
   **/

  hasFourCoords() {
    return (this.coords.length === 4)
  }

  assignLengths() {
    Object.assign(this.lengths, {
      a: distanceBetween(this.coords[0], this.coords[1]),
      b: distanceBetween(this.coords[1], this.coords[2]),
      c: distanceBetween(this.coords[2], this.coords[3]),
      d: distanceBetween(this.coords[3], this.coords[0])
    })
  }

  assignDiagonals() {
    Object.assign(this.diagonals, {
      x: distanceBetween(this.coords[0], this.coords[2]),
      y: distanceBetween(this.coords[1], this.coords[3])
    })
  }

  calculateAreaAcres() {
    this.areaAcres = (this.calculateAreaMetres() / ACRE)

    return this.areaAcres
  }

  getWidth() {
    return distanceInMetres(this.coords[1], this.coords[2])
  }

  getHeight() {
    return distanceInMetres(this.coords[0], this.coords[1])
  }

  calculateAreaMetres() {
    return (
      this.getWidth() * this.getHeight()
    )
  }

  getCentroid() {
    const coords = this.coords
    return [
      (coords[0][0] + coords[1][0] + coords[2][0] + coords[3][0]) / 4,
      (coords[0][1] + coords[1][1] + coords[2][1] + coords[3][1]) / 4
    ]
  }

  getLongitudeExtremes() {
    let min
    let max

    this.coords.forEach(([lng]) => {
      if (min === undefined || lng < min) min = lng
      if (max === undefined || lng > max) max = lng
    })

    return { min, max }
  }

  getLatitudeExtremes() {
    let min
    let max

    this.coords.forEach(([, lat]) => {
      if (min === undefined || lat < min) min = lat
      if (max === undefined || lat > max) max = lat
    })

    return { min, max }
  }

  getExtremes() {
    return {
      lat: this.getLatitudeExtremes(),
      lng: this.getLongitudeExtremes()
    }
  }

  getNEWS() {
    const { lat, lng } = this.getExtremes()

    return {
      north: lat.max,
      south: lat.min,
      east: lng.max,
      west: lng.min
    }
  }

  coordsValid() {
    const { north, south, east, west } = this.getNEWS()

    return (
      // Sanity checks for directions
      north > south &&
      east > west &&

      // Checks for max / min
      north <= 90 && north > -90 &&
      south < 90 && south >= -90 &&
      east <= 180 && east > -180 &&
      west < 180 && west >= -180
    )
  }

  /**
   * This will check the overlap with another shape
   *
   *
   * @param { Object: Shape } - Shape to check the overlap with,
   *                            must be rectangle for this to work
   * @returns { Float } - Overlap in units of (latitude * longitude)
   *
   *      ____________          ____________
   *      b(x,y) c(x,y)         b(x,y) c(x,y)
   *      |           |         |           |
   *      |     1     |         |     2     |
   *      |           |         |           |
   *      a(x,y)_d(x,y)         a(x,y)_d(x,y)
   *
   *
   **/
  overlapWith(anotherRectangle) {
    const [
      [ax, ay], , [cx, cy]
    ] = this.coords
    const [
      [ax2, ay2], , [cx2, cy2]
    ] = anotherRectangle.coords

    const xOverlap = Math.max(0, Math.min(cx, cx2) - Math.max(ax, ax2))
    const yOverlap = Math.max(0, Math.min(cy, cy2) - Math.max(ay, ay2))

    return (xOverlap * yOverlap)
  }

  /**
   * Expands the shape area by a provided factor
   * (factor of 2.0 means shape with double the area will be returned)
   *
   * @param { Float } areaFactor - Factor by which to expand the shape area
   * @returns { Float } - Overlap in units of (latitude * longitude)
   *
   *      _______________
   *      b(x,y)        c(x,y)
   *      |             |
   *      |             |
   *      | (CENx, CENy)|
   *      |             |
   *      |             |
   *      a(x,y)________d(x,y)
   *
   **/

  getResizedBy(areaFactor) {
    // We will find square root of the area factor to not quadruple the size
    const factor = Math.sqrt(areaFactor)

    // First calculate centroid
    const [CENx, CENy] = this.getCentroid()
    const [[ax, ay], [bx, by], [cx, cy], [dx, dy]] = this.coords

    return new Shape([
      [(CENx - (factor * (CENx - ax))), (CENy - (factor * (CENy - ay)))],
      [(CENx - (factor * (CENx - bx))), (CENy - (factor * (CENy - by)))],
      [(CENx - (factor * (CENx - cx))), (CENy - (factor * (CENy - cy)))],
      [(CENx - (factor * (CENx - dx))), (CENy - (factor * (CENy - dy)))]
    ])
  }

  /* Check this shape is rectangle (compare side and diagonal lengths)
   *
   * @returns { Boolean } - True if the shape is rectangle
   *
   *
   *      ______b_____
   *      1           2
   *      |           |
   *      a           c
   *      |           |
   *      0_____d_____3
   *
   *
   */
  isRectangle() {
    if (!this.hasFourCoords()) {
      return false
    } else {
      this.assignLengths()
      this.assignDiagonals()

      return (
        this.lengths.a > 0 &&
        this.lengths.b > 0 &&
        this.lengths.c > 0 &&
        this.lengths.d > 0 &&
        this.lengths.a === this.lengths.c &&
        this.lengths.b === this.lengths.d &&
        this.diagonals.x > 0 &&
        this.diagonals.y > 0 &&
        this.diagonals.x === this.diagonals.y
      )
    }
  }
}

module.exports = {
  coordsFromString,
  coordsToString,
  Shape,
}
